<template>
  <DefaultTemplate
    :footer="true"
    v-loading.lock="loading"
    element-loading-text="กำลังโหลดข้อมูล..."
    element-loading-spinner="el-icon-loading"
    id="assessment-plan"
  >
    <div>
      <h2 class="mg-y-2">
        <span
          class="cr-pointer"
          @click="$router.push(`/matching/assessment-plan`)"
        >
          Assessment Plan
        </span>
        >
        <span class="color-light-blue">Edit Assessment Plan</span>
      </h2>
      <div class="is-flex ai-center js-end mg-b-1 flex-wrap">
        <el-button
          round
          class="mg-t-2 font-14 button-history"
          :loading="loadingTemplate"
          @click="downloadTemplate"
        >
          <i class="fas fa-download mg-r-1"></i>Download Templated</el-button
        >
        <el-upload
          class="mg-x-2 import-data"
          action="#"
          :auto-upload="false"
          :on-change="(file, fileList) => uploadDocuments(file, fileList)"
          :limit="1"
          :file-list="fileImport"
          :on-exceed="handleExceed"
        >
          <el-button
            round
            class="mg-t-2 font-14 button-history"
            :loading="loadingImport"
            >Import</el-button
          >
        </el-upload>

        <el-button
          round
          class="mg-t-2 font-14 button-history"
          @click="exportData"
          :loading="loadingExport"
          >Export</el-button
        >
      </div>
    </div>
    <el-row :gutter="15" class="mg-b-5">
      <el-col :span="24">
        <el-card class="custom-card condition blue active" shadow="never">
          <div slot="header" class="clearfix">
            <span>Basic Information of Client (ข้อมูลบริษัท)</span>
          </div>
          <div v-if="!loading">
            <el-form
              :model="form"
              :rules="rules"
              ref="ruleForm"
              @submit.native.prevent="submitAssessment"
              :label-position="`top`"
            >
              <el-card shadow="never" class="card-inside normal">
                <AssessmentCompanyEdit :form="form" />
                <div class="js-between is-flex mg-t-4">
                  <el-button
                    round
                    @click="$router.push(`/matching/assessment-plan`)"
                  >
                    <span class="font-14"> Back </span>
                  </el-button>

                  <el-button
                    type="warning"
                    round
                    :loading="loadingBtn"
                    native-type="submit"
                  >
                    <span class="font-14"> Save </span>
                  </el-button>
                </div>
              </el-card>
            </el-form>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </DefaultTemplate>
</template>

<script>
import "@/mixin/DefaultTemplate";
import { HTTP } from "@/service/axios";
import { mapState } from "vuex";
import AssessmentCompanyEdit from "@/components/assessment-plan/AssessmentCompanyEdit";
export default {
  components: {
    AssessmentCompanyEdit,
  },
  computed: {
    ...mapState({
      _token: (state) => state._token,
      setCsrf: (state) => state.setCsrf,
    }),
  },
  mounted() {
    this.getAssessmentPlanById();
  },
  data() {
    return {
      loading: true,
      loadingBtn: false,
      loadingTemplate: false,
      loadingImport: false,
      loadingExport: false,
      imageUrl: process.env.VUE_APP_BASE_URL_IMAGE,
      fileUrl: process.env.VUE_APP_BASE_URL_FILE,

      form: {},
      fileImport: [],
      rules: {
        planName: [
          {
            required: true,
            message: "กรุณากรอกชื่อแผน",
            trigger: "blur",
          },
        ],
        date: [
          {
            required: true,
            message: "กรุณาเลือกวันที่",
            trigger: "change",
          },
        ],
        file: [
          {
            type: "array",
            required: true,
            message: "กรุณาอัปโหลดโลโก้",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    async getAssessmentPlanById(type) {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      delete HTTP.defaults.headers.common["X-CSRF-TOKEN"];

      HTTP.get(`matching/assessment/` + this.$route.params.id)
        .then(async (res) => {
          if (res.data.success) {
            if (type != "import") {
              this.form = res.data.obj;
              this.form.matching = await this.form.matching.map((row) => {
                let setCat = row.companyType.catNo.join(", ");
                return {
                  ...row,
                  dateTime:
                    this.formatDate(row.date) +
                    " " +
                    row.timeStart +
                    " - " +
                    row.timeEnd,
                  companyType: {
                    ...row.companyType,
                    catNo: setCat,
                  },
                };
              });
              this.form.isOffSite = this.form.matching.some(
                (item) => item.typeOfVisit === 1
              );
              this.form.isOnSite = this.form.matching.some(
                (item) => item.typeOfVisit === 2
              );
              this.form.isRemote = this.form.matching.some(
                (item) => item.typeOfVisit === 3
              );
              this.form.dateOld = this.form.date;
              this.form.file = [];
              if (this.form.logoPlan) {
                let setUrl =
                  this.imageUrl +
                  "assessment-plan/" +
                  this.form._id +
                  "/" +
                  this.form.logoPlan;
                let obj = {
                  name: this.form.logoPlan,
                  url: setUrl,
                  _id: this.form.logoPlan,
                };
                this.form.file.push(obj);
              }
              this.form.delFile = [];
            } else {
              this.form.dataImport = res.data.obj.dataImport;
            }
          } else {
            this.$router.push(`/matching/assessment-plan`);
            this.$notify({
              title: "ไม่พบ Assessment Plan !",
              type: "error",
              customClass: "error",
            });
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    submitAssessment() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.loadingBtn = true;
          HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
          const config = {
            headers: {
              "Content-Type": "multipart/form-data",
              "X-CSRF-TOKEN": this.setCsrf,
            },
          };
          var formData = new FormData();

          formData.append("_csrf", this.setCsrf);
          formData.append("planName", this.form.planName);
          formData.append("date", this.form.date);
          formData.append("clientSign", this.form.clientSign);
          formData.append("leadAuditorSign", this.form.leadAuditorSign);
          if (this.form.file[0].file != undefined) {
            formData.append("file", this.form.file[0].file.raw);
          }

          formData.append("delFile", this.form.delFile);
          HTTP.put(
            `matching/assessment/edit/` + this.$route.params.id,
            formData,
            config
          )
            .then((res) => {
              if (res.data.success) {
                this.$notify({
                  title: "แก้ไข Assessment Plan สำเร็จ!",
                  type: "success",
                  customClass: "success",
                });
                this.$router.push(`/matching/assessment-plan`);
              } else {
                this.loadingBtn = false;
                this.$notify({
                  title: "ชื่อแผนซ้ำ!",
                  type: "error",
                  customClass: "error",
                });
              }
            })
            .catch((e) => {
              if (typeof e.response != "undefined") {
                if (e.response.data.status == 401) {
                  this.checkAuth();
                }
              }
            })
            .finally(() => {
              this.loadingBtn = false;
            });
        }
      });
    },
    downloadTemplate() {
      this.loadingTemplate = true;
      let columnsTemplate = ["date", "startTime", "process", "person"];

      let csvContent = "";
      csvContent += [columnsTemplate.join(",")]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      var link = window.document.createElement("a");
      link.setAttribute(
        "href",
        "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvContent)
      );
      link.setAttribute("download", "assessment_plan.csv");
      this.$notify({
        title: "Download Template ข้อมูลสำเร็จ!",
        type: "success",
        customClass: "success",
      });
      link.click();
      setTimeout(() => {
        this.loadingTemplate = false;
      }, 200);
    },

    async uploadDocuments(file, fileList) {
      this.loadingImport = true;
      let typeThis = file.raw.name.split(".");
      let type = file.raw.type.split("/");
      let indexOfFile = typeThis.length > 1 ? typeThis.length - 1 : 1;
      if (type[indexOfFile] != "json") {
        if (file.size < 25000000) {
          if (
            typeThis[indexOfFile] == "xlsx" ||
            typeThis[indexOfFile] == "xls" ||
            typeThis[indexOfFile] == "csv"
          ) {
            if (typeThis[0] == "assessment_plan") {
              let obj = {
                name: file.name,
                file: file,
              };
              this.fileImport.push(obj);
              await this.importPlan();
            } else {
              let index = fileList.findIndex((a) => a.uid == file.uid);
              fileList.splice(index, 1);
              this.$message({
                message: "ชื่อไฟล์อัพโหลดไม่ถูกต้อง",
                type: "error",
                duration: 4000,
              });
              this.loadingImport = false;
            }
          } else {
            this.$message({
              message: "กรุณาเลือกไฟล์นามสกุลเป็น CSV หรือ XLSX เท่านั้น",
              type: "error",
              duration: 4000,
            });
            let index = fileList.findIndex((a) => a.uid == file.uid);
            fileList.splice(index, 1);
            this.loadingImport = false;
          }
        } else {
          let index = fileList.findIndex((a) => a.uid == file.uid);
          fileList.splice(index, 1);
          this.$message({
            message: "กรุณาเลือกไฟล์ขนาดไม่เกิน 25 MB",
            type: "error",
            duration: 4000,
          });
          this.loadingImport = false;
        }
      } else {
        let index = fileList.findIndex((a) => a.uid == file.uid);
        fileList.splice(index, 1);
        this.$message({
          message: "ไม่สามารถอัพโหลดไฟล์นามสกุล JSON ได้",
          type: "error",
          duration: 4000,
        });
        this.loadingImport = false;
      }
    },
    async importPlan() {
      this.loadingImport = true;
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRF-TOKEN": this.setCsrf,
        },
      };
      var formData = new FormData();

      formData.append("_csrf", this.setCsrf);
      formData.append("assessmentPlanId", this.$route.params.id);
      if (this.fileImport[0].file != undefined) {
        formData.append("file", this.fileImport[0].file.raw);
      }

      HTTP.post(`matching/assessment/import`, formData, config)
        .then(async (res) => {
          if (res.data.success) {
            this.$notify({
              title: "Import Assessment Plan สำเร็จ!",
              type: "success",
              customClass: "success",
            });
            this.fileImport = [];
            await this.getAssessmentPlanById("import");
            // this.$router.push(`/matching/assessment-plan`);
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            }
          }
        })
        .finally(() => {
          this.loadingImport = false;
        });
    },
    exportData() {
      this.loadingExport = true;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      delete HTTP.defaults.headers.common["X-CSRF-TOKEN"];

      HTTP.get(`matching/assessment/export/` + this.$route.params.id)
        .then((res) => {
          if (res.data.success) {
            var file_path = this.fileUrl + res.data.obj.filename;
            var a = document.createElement("A");
            a.href = file_path;
            a.download = file_path.substr(file_path.lastIndexOf("/") + 1);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            this.$notify({
              title: "Export Assessment Plan สำเร็จ!",
              type: "success",
              customClass: "success",
            });
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            }
          }
        })
        .finally(() => {
          this.loadingExport = false;
        });
    },
    handleExceed() {
      this.$message.warning(`อัปโหลดได้สูงสุด 1 ไฟล์`);
    },
  },
};
</script>
