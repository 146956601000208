<template>
  <el-row :gutter="15" type="flex" class="flex-wrap">
    <el-col :span="24">
      <div class="group-date-type">
        <el-card shadow="never" class="card-left">
          <div class="card-date-time">
            <span>
              <i class="fas fa-calendar-alt"></i>
              <span class="font-special"> Date & Time (วันที่และเวลา) </span>
            </span>
            <div class="is-flex ai-center">
              <div class="set-card">
                {{ form.dateOld }}
              </div>
            </div>
          </div>
        </el-card>
        <el-card shadow="never" class="card-right">
          <span>
            <i class="fas fa-calendar-alt"></i>
            <span class="font-special"> วิธีการเข้าตรวจรับ </span>
          </span>
          <div class="typeOfVisit color-main">
            <span v-if="form.isOffSite"> Off-site </span>
            <span v-if="form.isOnSite"> On-site </span>
            <span v-if="form.isRemote"> Remote </span>
          </div>
        </el-card>
      </div>
    </el-col>

    <el-col :span="24" class="mg-y-5">
      <el-row :gutter="15" type="flex" class="flex-wrap">
        <el-col :span="24" :md="12">
          <el-form-item label="Logo (อัปโหลดโลโก้)" prop="file">
            <el-upload
              multiple
              action="#"
              :auto-upload="false"
              :on-change="(file, fileList) => uploadDocuments(file, fileList)"
              :on-remove="(file) => handleRemoveDocuments(file)"
              list-type="picture"
              :file-list="form.file"
              :on-preview="(file) => perviewFile(file)"
              :limit="1"
              :on-exceed="handleExceed"
            >
              <el-button class="btn-upload w-100">
                <span>Click to upload </span>
              </el-button>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="24" :md="12">
          <el-form-item label="Plan Name (ชื่อแผน)" prop="planName">
            <el-input
              placeholder="Plan Name"
              v-model="form.planName"
              clearable
              @blur="form.planName = form.planName.trim()"
            >
            </el-input>
          </el-form-item>

          <el-form-item label="Date (วันที่)" prop="date">
            <el-input placeholder="Date (วันที่)" v-model="form.date" clearable>
            </el-input>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-row :gutter="15" type="flex" class="flex-wrap">
            <el-col :span="24" :md="12">
              <el-form-item label="Company name (ชื่อบริษัท)">
                <el-input v-model="form.companyName" disabled> </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-col>

    <el-col :span="24">
      <el-form-item label="Address Of Client (ที่อยู่)">
        <el-input v-model="form.address" disabled> </el-input>
      </el-form-item>
    </el-col>
    <el-col :span="24" :md="12">
      <el-form-item label="Contact person name (ชื่อผู้ติดต่อ)">
        <el-input v-model="form.contactPerson" disabled> </el-input>
      </el-form-item>
    </el-col>

    <el-col :span="24" :md="12">
      <el-form-item label="Phone number (เบอร์โทร)">
        <el-input v-model="form.phone" disabled> </el-input>
      </el-form-item>
    </el-col>
    <el-col :span="24" class="mg-y-2"> <hr /> </el-col>
    <el-col
      :span="24"
      class="mg-b-5"
      v-for="(matching, index) in form.matching"
      :key="index"
    >
      <el-row :gutter="15" type="flex" class="flex-wrap">
        <el-col :span="24" :md="12">
          <el-form-item :label="`Auditor ${index + 1} (ผู้ตรวจ ${index + 1})`">
            <el-input v-model="matching.auditor.fullName" disabled> </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" :md="12">
          <el-form-item label="Email (อีเมล)">
            <el-input v-model="matching.auditor.email" disabled> </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" :md="12">
          <el-form-item label="Phone number (เบอร์โทร)">
            <el-input v-model="matching.auditor.phone" disabled> </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" :md="12">
          <el-form-item label="Date / Time (วัน เวลา)">
            <el-input v-model="matching.dateTime" disabled> </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" :md="6">
          <el-form-item label="Type (ประเภท)">
            <el-select clearable v-model="matching.companyType.type" disabled>
              <el-option
                v-for="item in typeMaster"
                :key="item.typeNum"
                :label="`ตรวจ ` + item.typeName"
                :value="item.typeNum"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" :md="6">
          <el-form-item label="Company (บริษัท)" prop="company">
            <el-select
              clearable
              v-model="matching.companyType.company"
              placeholder=""
              disabled
            >
              <el-option
                v-for="item in optionsCompanyIso"
                :key="item.companyNum"
                :label="item.companyName"
                :value="item.companyNum"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" :md="6">
          <el-form-item label="Standard (มาตรฐาน)">
            <el-select
              clearable
              v-model="matching.companyType.standard"
              placeholder=""
              disabled
            >
              <template slot="empty">
                <p class="el-select-dropdown__empty">ไม่มีข้อมูล</p>
              </template>
              <el-option
                v-for="item in matching.companyType.type == 1
                  ? optionsStandardFood
                  : matching.companyType.company == 1
                  ? optionsStandardIsoOne
                  : optionsStandardIsoTwo"
                :label="item.standardName"
                :value="item.standardNum"
                :key="item.standardNum"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" :md="6" v-if="matching.companyType.standard == 99">
          <el-form-item class="no-label">
            <el-input
              clearable
              v-model="matching.companyType.other"
              placeholder=""
              disabled
            />
          </el-form-item>
        </el-col>

        <el-col :span="24" :md="6">
          <el-form-item label="CAT No.">
            <el-input
              v-model="matching.companyType.catNo"
              disabled
              id="catNo"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" :md="6">
          <el-form-item label="Audit cycle (รอบการตรวจติดตาม)">
            <el-select
              v-model="matching.companyType.auditCycle"
              placeholder=""
              disabled
            >
              <el-option
                v-for="item in optionsAuditCycle"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-col>
    <el-col :span="24" :md="12">
      <el-form-item label="Confirm by customer" prop="clientSign">
        <el-input
          placeholder="Confirm by customer"
          v-model="form.clientSign"
          clearable
        >
        </el-input>
      </el-form-item>
    </el-col>
    <el-col :span="24" :md="12">
      <el-form-item label="Review by Lead auditor" prop="leadAuditorSign">
        <el-input
          placeholder="Review by Lead auditor"
          v-model="form.leadAuditorSign"
          clearable
        >
        </el-input>
      </el-form-item>
    </el-col>
    <el-col :span="24">
      <TableAssessmentImport
        :data="form.dataImport"
        class="mg-b-3"
        :viewOnly="true"
      />
    </el-col>
  </el-row>
</template>
<script>
import { mapState } from "vuex";
import { HTTP } from "@/service/axios";
import TableAssessmentImport from "@/components/table/TableAssessmentImport";

export default {
  components: {
    TableAssessmentImport,
  },
  props: {
    form: {
      type: Object,
      default: {},
    },
    detailPlan: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapState({
      _token: (state) => state._token,
      typeMaster: (state) => state.typeMaster,
      optionsStandardFood: (state) => state.optionAll.optionsStandardFood,
      optionsCompanyIso: (state) => state.optionAll.optionsCompanyIso,
      optionsStandardIsoOne: (state) => state.optionAll.optionsStandardIsoOne,
      optionsStandardIsoTwo: (state) => state.optionAll.optionsStandardIsoTwo,
      optionsAuditCycle: (state) => state.optionAll.auditCycle,
    }),
  },

  mounted() {},
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          const today = new Date();
          return time.getTime() < today.setDate(today.getDate() - 1);
        },
      },
    };
  },
  methods: {
    uploadDocuments(file, fileList) {
      let typeThis = file.raw.name.split(".");
      let type = file.raw.type.split("/");
      let indexOfFile = typeThis.length > 1 ? typeThis.length - 1 : 1;
      if (type[indexOfFile] != "json") {
        if (file.size < 25000000) {
          if (
            type[indexOfFile] == "jpeg" ||
            type[indexOfFile] == "png" ||
            type[indexOfFile] == "jpg"
          ) {
            let obj = {
              name: file.name,
              file: file,
            };
            this.form.file.push(obj);
          } else {
            this.$message({
              message: "กรุณาเลือกไฟล์นามสกุลเป็น JPG , JPEG หรือ PNG เท่านั้น",
              type: "error",
              duration: 4000,
            });
            let index = fileList.findIndex((a) => a.uid == file.uid);
            fileList.splice(index, 1);
          }
        } else {
          let index = fileList.findIndex((a) => a.uid == file.uid);
          fileList.splice(index, 1);
          this.$message({
            message: "กรุณาเลือกไฟล์ขนาดไม่เกิน 25 MB",
            type: "error",
            duration: 4000,
          });
        }
      } else {
        let index = fileList.findIndex((a) => a.uid == file.uid);
        fileList.splice(index, 1);
        this.$message({
          message: "ไม่สามารถอัพโหลดไฟล์นามสกุล JSON ได้",
          type: "error",
          duration: 4000,
        });
      }
    },

    handleRemoveDocuments(file) {
      if (file._id != undefined) {
        this.form.delFile.push(file.name);
        this.form.file.splice(0, 1);
      }
    },
    perviewFile(file) {
      if (file.raw == undefined && file.raw == null) {
        window.open(file.url);
      } else {
        window.open(URL.createObjectURL(file.raw));
      }
    },
    handleExceed() {
      this.$message.warning(`อัปโหลดได้สูงสุด 1 ไฟล์`);
    },

    changeTime() {
      if (this.form.timeStart >= this.form.timeEnd) {
        this.form.timeEnd = "";
      }
    },
  },
};
</script>
